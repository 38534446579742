import React from "react"

import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { convertToBgImage } from 'gbimage-bridge'
import BackgroundImage from 'gatsby-background-image'
import { formatTimestamp } from "../utils/dates"

import kebabCase from "lodash/kebabCase"

const TagsGridTile = ({ tag }) => {
  const { fieldValue, totalCount, nodes } = tag;
  const featuredRecipe = nodes[0];
  const image = getImage(featuredRecipe.frontmatter.featured);
  //let { slug } = recipe.fields
  //let { date, title, description, featured } = recipe.frontmatter
  //const image = getImage(featured)
  const tagSlug = kebabCase(fieldValue);

  const bgImage = convertToBgImage(image);

  return (
    <article
      key={tagSlug}
      className="overflow-hidden align-stretch items-stretch self-stretch relative hover:ring"
    >
      <Link
        to={`/tags/${tagSlug}`}
        className="bg-white -mb-8 hover:no-underline"
      >
        <div
          className="absolute inset-0 z-10"
          style={{
            background: "linear-gradient(to bottom, rgba(255,255,255,0.95) 10%, rgba(255,255,255,0.6))"
          }}
        />
        <h3 className="font-bold absolute text-2xl hover:underline inline p-8 z-20">
          {fieldValue}
        </h3>
        <GatsbyImage image={image} className="z-0" />
      </Link>
    </article>
  )
}

export default TagsGridTile
