import React from "react"
import Layout from "../components/Layout"
import { Link, graphql } from "gatsby"
import SEO from "../components/SEO"
import { formatTimestamp } from '../utils/dates'
import kebabCase from "lodash/kebabCase"

import RecipeGridHeaderBlock from '../components/RecipeGridHeaderBlock'
import TagsGridTile from '../components/TagsGridTile';

export default function Tags({ 
  data: {
    allMarkdownRemark: { group },
    site: {
      siteMetadata: { title }
    }
  }, 
  location
}) {
  return (
    <Layout>
      <SEO
        title="Tags"
        description="All the recipes from Andrew Johnson"
        keywords={[`recipes`, `baking`, `Andrew Johnson`]}
        pathname={location.pathname}
      />
      <main className="space-y-6 mx-auto container px-4 pb-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
          <RecipeGridHeaderBlock>
            <h2 className="font-bold sm:text-right">All Tags</h2>
            <h2
              aria-hidden="true"
              className="font-bold self-end hidden sm:block transform rotate-90 select-none"
            >
              ⤴
            </h2>
          </RecipeGridHeaderBlock>
          {group.map(tag => (
            <TagsGridTile tag={tag}/>
          ))}
        </div>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags, limit: 1) {
        fieldValue
        totalCount
        nodes {
          frontmatter {
            featured {
              childImageSharp {
                gatsbyImageData(width: 1024, aspectRatio: 1)
              }
            }
            description
          }
        }
      }
    }
  }
`
